import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { myApi } from 'store/Base';
import { BinderSocket } from '../../../mobx-spine';
import { action, observable } from 'mobx';
import { Content } from 're-cy-cle';
import { Header, Table } from 'semantic-ui-react';
import { HealthStatus, subscribeToHealthStatus } from '../helpers/highTemplar';

@observer
export class HighTemplarStatusScreen extends Component {

    protected socket: BinderSocket | null;

    protected unsubscribe: (() => void) | null = null;

    @observable openConnections = 0;
    @observable numberOfRooms = 0;
    @observable onlineSince = '';
    @observable rabbitmqMessagesReceived = 0;
    @observable wsMessagesReceived = 0;
    @observable wsMessagesSend = 0;
    @observable wsMessagesSendError = 0;
    @observable wsMessagesQueued = 0;


    constructor(args) {
        super(args)
        this.socket = myApi.socket;
    }

    componentDidMount() {
        if (!this.socket) {
            return
        }
        this.unsubscribe = subscribeToHealthStatus(
            this.socket,
            this.onHealthMessageReceived.bind(this)
        )
    }


    @action
    protected onHealthMessageReceived(healthStatus: HealthStatus) {
        this.openConnections = healthStatus.open_connections;
        this.numberOfRooms = healthStatus.num_rooms;
        this.onlineSince = healthStatus.online_since;
        this.rabbitmqMessagesReceived = healthStatus.rabbitmq_messages_received;
        this.wsMessagesReceived = healthStatus.ws_messages_received;
        this.wsMessagesSend = healthStatus.ws_messages_send
        this.wsMessagesSendError = healthStatus.ws_messages_send_error;
        this.wsMessagesQueued = healthStatus.ws_messages_send_queued

    }


    render() {
        if (!this.socket) {
            return (
                <>
                    No sockets found
                </>
            )
        }
        return (
            <Content>
                <Header>Status high templar</Header>
                <Table>
                    <Table.Row>
                        <Table.HeaderCell>
                            Open connections
                        </Table.HeaderCell>
                        <Table.Cell>
                            {this.openConnections}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>
                            Number of rooms
                        </Table.HeaderCell>
                        <Table.Cell>
                            {this.numberOfRooms}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>
                            Online since
                        </Table.HeaderCell>
                        <Table.Cell>
                            {this.onlineSince}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>
                            RabbitMQ Messages received
                        </Table.HeaderCell>
                        <Table.Cell>
                            {this.rabbitmqMessagesReceived}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>
                            Websocket messages received
                        </Table.HeaderCell>
                        <Table.Cell>
                            {this.wsMessagesReceived}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>
                            Websocket messages send
                        </Table.HeaderCell>
                        <Table.Cell>
                            {this.wsMessagesSend}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>
                            Websocket messages send error
                        </Table.HeaderCell>
                        <Table.Cell>
                            {this.wsMessagesSendError}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>
                            Queued websocket messages
                        </Table.HeaderCell>
                        <Table.Cell>
                            {this.wsMessagesQueued}
                        </Table.Cell>
                    </Table.Row>
                </Table>
            </Content>
        )
    }
}
