import { ModuleInterface } from '../module/module';
import { NavItem } from 're-cy-cle';
import React from 'react';
import { HighTemplarStatusScreen } from './screen/HighTemplar';
import { t } from 'i18next';

export const Module: ModuleInterface = {
    name: 'system-status',
    navigationMenu: () => ({
        basePath: '/system-status/',
        topMenuItem: (
            <NavItem
                title={t('nav.main.status')}
                to="/system-status/high-templar"
                activePath="/system-status/"
            />
        ),
        subMenuItems: []
    }),
    routes: [
        {
            path: '/system-status/high-templar',
            screen: HighTemplarStatusScreen
        }
    ],
    translations: {}

}
