export function deepMerge(target, source) {
  if (typeof target !== 'object' || target === null) {
    // If target is not an object or null, return source
    return source;
  }

  if (Array.isArray(source)) {
    // If source is an array, use concat to merge
    return target.concat(source)
  }

  const merged = Object.assign({}, target);

  // eslint-disable-next-line no-unused-vars
  for (const key of Object.keys(source)) {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (typeof targetValue === 'object' && targetValue !== null) {
      // If target value is an object, recursively merge
      merged[key] = deepMerge(targetValue, sourceValue);
    } else {
      // Otherwise, simply assign source value
      merged[key] = sourceValue;
    }
  }

  return merged;
}