import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { Progress } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import subscribe from '../../../decorator/subscribe';


interface ExportProgressBarProps {
    target: string;
    uuid: string;
    isCompleted?: boolean;
    onCompleted?: () => void;
    triggeredBy: '*' | number
}

@observer

export class ExportProgressBar extends subscribe(Component<ExportProgressBarProps>) {

    @observable percentageDone = 0;

    componentDidMount() {
        const { target, uuid, onCompleted, triggeredBy } = this.props;

        this.subscribe({
                target: target,
                uuid: uuid,
                triggered_by: triggeredBy
            },
            action(({ data }) => {
                this.percentageDone = data.percentage_done

                if (this.percentageDone === 1) {
                    onCompleted && onCompleted()
                }
            })
        )
    }


    render() {
        const { isCompleted,  ...rest } = this.props;

        // If the file is completed,we do not get updates anymore
        const percentageDone = isCompleted ? 1: this.percentageDone;

        return (
            <Progress progress indicating autoSuccess {...rest}
                      precision={2}
                      data-test-progress={percentageDone}
                      total={100}
                      value={100 * percentageDone}
            />
        );
    }


}
