import React from 'react';
import AdminOverview from 'component/AdminOverview';
import { Download, DownloadStore } from '../store/Download';
import { t } from '../../../i18n';
import UserAvatar from '../../../component/UserAvatar';
import { DATETIME_FORMAT } from '../../../helpers';
import { DownloadButton } from '@code-yellow/spider';
import { DownloadModal } from '../component/DownloadModal';
import { observable } from 'mobx';
import { ItemButton } from '@code-yellow/spider';
import { UserStore } from '../../../store/User'
import { User } from '@sentry/react';

export class DownloadOverviewScreen extends AdminOverview<Download, DownloadStore> {
    store = new DownloadStore({
        relations: [
            'triggeredBy'
        ]
    });

    userStore = new UserStore();

    title = t('download.overview.title');
    // myFilterKey = 'download-overview';


    rowProps(item, i) {
        const superProps = super.rowProps(item, i);

        return {
            ...superProps,
            'data-test-download': item.id
        };
    }


    settings = [
        { attr: 'uuid', cellProps: { 'data-test-key': true } },
        { attr: 'source' },
        {
            attr: (download: Download) => {
                return <UserAvatar user={download.triggeredBy} withName/>
            },
            label: t('download.field.triggeredBy.label'),
            sortKey: 'triggered_by.full_name',
        },
        {
            attr: (download: Download) => download.triggeredAt.format(DATETIME_FORMAT),
            label: t('download.field.triggeredAt.label'),
            sortKey: 'triggered_at',
        },
        {
            attr: (download: Download) => download.readyAt?.format(DATETIME_FORMAT) ?? '',
            label: t('download.field.readyAt.label'),
            sortKey: 'triggered_at',
        },
        '',
    ];

    buttons = [
        (download: Download, i) => (
            <ItemButton
                key={i}
                label={'Download'}
                icon={download.readyAt ? 'download': 'sync'}
                onClick={() => {
                    this.download = download
                }}
                {...this.itemButtonProps}
            />
        )
    ]


    toolbar = [
        {
            type: 'custom' as const,
            callback: () => {
                return (
                    <DownloadButton
                        primary
                        data-test-download-downloads-button
                        onClick={() => this.startDownload()}
                    />
                )
            }
        }
    ]

    filters = [
        {
            type: 'text' as const,
            label: t('download.field.uuid.label'),
            name: '.uuid:icontains',
        },
        {
            type: 'select' as const,
            remote: true as const,
            label: t('download.field.triggeredBy.label'),
            name: '.triggered_by.id:in',
            clearable: true,
            store: new UserStore(),
            toOption: (model: User) => ({
                value: model.id,
                text: <UserAvatar user={model} withName />
            })
        }
    ]

    renderContent() {
        return (
            <>
                <DownloadModal
                    download={this.download}
                    onClose={() => {
                        this.download = null;
                    }}
                />
                {super.renderContent()}
            </>
        )
    }

    @observable download: Download | null = null;

     startDownload = async () => {
        this.download = await this.store.createAsyncDownload()
    }
}
