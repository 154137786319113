import { deepMerge } from './helpers';
import en from './translationJSON/en.json';

const tsJson = {
    nav: {
        main: {
            fileHandling: 'Files'
        }
    },
    download: {
        overview: {
            title: 'Downloads'
        },
        field: {
            source: {
                label: 'Source'
            },
            uuid: {
                label: 'UUID'
            },
            triggeredBy: {
                label: 'Triggered By',
            },
            triggeredAt: {
                label: 'Triggered At',
            },
            readyAt: {
                label: 'Ready at'
            }
        },
        progress: 'Download progress'
    }
}

export default deepMerge(tsJson, en)