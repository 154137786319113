import nl from './translationJSON/nl.json';
import { deepMerge } from './helpers';

const tsJson = {
    nav: {
        main: {
            fileHandling: 'Bestanden'
        }
    },
    download: {
        overview: {
            title: 'Downloads'
        },
        field: {
            source: {
                label: 'Bron'
            },
            uuid: {
                label: 'UUID'
            },
            triggeredBy: {
                label: 'Aangemaakt Door',
            },
            triggeredAt: {
                label: 'Aangevraagd Op',
            },
            readyAt: {
                label: 'Afgerond op'
            }
        },
        progress: 'Downloadprogressie'
    }
}
export default deepMerge(tsJson, nl)
