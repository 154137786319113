import React, { Component } from 'react';
import { Heading, Subheading, Button } from 're-cy-cle';

import FullScreenOverlay from 'component/FullScreenOverlay';

export default class RuntimeError extends Component {
    render() {
        return (
            <FullScreenOverlay>
                <Heading>{t('crash.heading')}</Heading>
                <Subheading>
                    {t('crash.subHeading1')}<br/>
                    {t('crash.subHeading2')}<br/>
                </Subheading>
                <Button onClick={() => document.location.reload()}>
                    {t('crash.reload')}
                </Button>
            </FullScreenOverlay>
        );
    }
}
