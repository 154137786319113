import React, { Component } from 'react';
import { Download } from '../store/Download';
import { Modal } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { ExportProgressBar } from './ProgressBar';
import { FileDownloadButton } from './FileDownloadButton';
import { CancelButton } from '@code-yellow/spider';
import { getCurrentUser } from '../../../helpers/currentUser';
import { t } from 'i18next';

interface DownloadModalProps {
    download: Download | null;
    onClose?: () => void;
}


@observer
export class DownloadModal extends Component<DownloadModalProps> {

    async updateData() {
        const { download } = this.props;
        await download?.fetch();
    }



    render() {
        const { download, onClose } = this.props;

        if (download === null) {
            return (
                <></>
            );
        }

        return (

            <Modal open={true} onClose={() => {
                onClose && onClose()
            }}>
                <Modal.Header>{t('download.progress')}</Modal.Header>
                <Modal.Content>
                    <ExportProgressBar
                        uuid={download.uuid}
                        target="download"
                        isCompleted={download.readyAt !== null}
                        onCompleted={this.updateData.bind(this)}
                        triggeredBy={getCurrentUser().id as number}
                    />
                    {download.readyAt && <FileDownloadButton
                        download={download}
                    />}
                </Modal.Content>
                <Modal.Actions>
                    <CancelButton
                        content={t('download.close')}
                        data-test-close-download-modal
                        onClick={() => onClose && onClose()}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}
